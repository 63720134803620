<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="homeself" @click="gotohomeself(userinfo)">
        <p class="red">他的首页</p>
    </div>
    <div >
        <div class="head">
            <p class="front">最高{{userinfo.continuousHit}}连中</p>
            <p class="after red">近10中{{userinfo.hitPost}}</p>
        </div>
        <div class="body">
            <div class="item" v-for="(itemone,index) in postLists" :key="index" @click="gotoPost(itemone)">
                <div class="issue">
                    <p>第{{itemone.periodText}}</p>
                    <p v-if="(itemone.isLack===2)" class="red">待开奖</p>
                    <p v-else class="red">{{itemone.lotteryContent}}</p>
                </div>
                <div class="info">
                    <div >
                        <div v-if="itemone.forumDetailsModel!==null">
                            <p :class="itemone. forumDetailsModel.wonLotterySts===1?'red':'black'">[{{itemone. forumDetailsModel.categoryName}}]</p>
                            <div v-if="itemone. forumDetailsModel.categoryName==='杀头'||itemone. forumDetailsModel.categoryName==='杀百'||itemone. forumDetailsModel.categoryName==='杀十'||itemone. forumDetailsModel.categoryName==='杀尾'">
                            <p v-if="itemone. forumDetailsModel.textIndexPoint1!==''">
                                千位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint1Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1Major" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint1Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1Focus" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="itemone. forumDetailsModel.textIndexPoint2!==''">
                                百位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint2Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2Major" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint2Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2Focus" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="itemone. forumDetailsModel.textIndexPoint3!==''">
                                十位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint3Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3Major" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint3Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3Focus" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="itemone. forumDetailsModel.textIndexPoint4!==''">
                                个位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint4Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4Major" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint4Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4Focus" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='死数'">
                            <p v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPointGeneralMajor!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralMajor" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPointGeneralFocus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralFocus" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='稳码'">
                            <p v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPointGeneralMajor!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPointGeneralFocus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='头尾合'">
                            <p v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPointGeneralMajor!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPointGeneralFocus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='中肚合'">
                            <p v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPointGeneralMajor!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralMajor" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPointGeneralFocus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneralFocus" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='二字现'">
                            <p v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_twoshow.includes(item)?'red':'black'">{{item}} </span> 
                            </p>
                            </div>
                            <div v-else-if="itemone. forumDetailsModel.categoryName==='三字现'">
                            <p style="word-break:break-word;" v-if="itemone. forumDetailsModel.textIndexPointGeneral!==''">
                                
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_threeshow.includes(item)?'red':'black'">{{item}} </span> 
                                
                            </p>
                            </div>
                            <div v-else>
                            <p v-if="(itemone. forumDetailsModel.textIndexPoint1!==''&&itemone. forumDetailsModel.textIndexPoint1!==undefined)">
                                千位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint1Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1Major" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint1Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint1Focus" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="(itemone. forumDetailsModel.textIndexPoint2!==''&&itemone. forumDetailsModel.textIndexPoint2!==undefined)">
                                百位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint2Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2Major" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint2Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint2Focus" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="(itemone. forumDetailsModel.textIndexPoint3!==''&&itemone. forumDetailsModel.textIndexPoint3!==undefined)">
                                十位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint3Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3Major" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint3Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint3Focus" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            <p v-if="(itemone. forumDetailsModel.textIndexPoint4!==''&&itemone. forumDetailsModel.textIndexPoint4!==undefined)">
                                个位：
                                <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                <span v-if="itemone. forumDetailsModel.textIndexPoint4Major!==''">
                                    主攻
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4Major" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                    <span v-if="itemone. forumDetailsModel.textIndexPoint4Focus!==''">
                                    重点
                                    <span v-for="(item,index) in itemone. forumDetailsModel.textIndexPoint4Focus" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                    </span>
                                </span>
                            </p>
                            </div>
                        </div>
                        <div v-else>
                            <p>缺，未发表</p>
                        </div>
                    </div>
                    
                </div>
                <div class="icon">
                    <div v-if="(itemone.forumDetailsModel!==null)">
                        <van-icon v-if="(itemone.forumDetailsModel.wonLotterySts===1)" name="checked" color="red" size="20"/>
                        <van-icon v-if="(itemone.forumDetailsModel.wonLotterySts===0)" name="clear" color="gray" size="20"/>
                    </div>
                    <div v-else>
                        <van-icon  name="question-o" color="gray" size="20"/>

                    </div>
                  
                    <p>></p>
                </div>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default {
    data(){
        return{
                title:this.$route.query.title,
                schemename:this.$route.query.schemename,
                userinfo:JSON.parse(this.$route.query.userinfo),
                postList:[
                    {periodText:'2815',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2816',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2817',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2818',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2819',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2820',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2821',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2822',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2823',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2824',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                  
                ],
                postLists:[],
        }
    },
    components:{
        vHeader
    },
    methods:{
        async getmasterTenPostInfo(){
            let data={
                    categoryName:this.schemename,
                    userId:this.userinfo.userId,
                    lotteryKind:this.$store.state.common.lotteryKind,
                   
                }
            const res = await this.$HTTP.post(this, 'API_MASTERINFO_TEN_ONE', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                    if (res.statusCode === 200) {
                        console.log('近十期信息',res)
                        let arr=res.data.map(item=>{
                            const qian=item.lotteryContent!==null?item.lotteryContent.substr(0,1):'', bai=item.lotteryContent!==null?item.lotteryContent.substr(1,1):'',shi=item.lotteryContent!==null?item.lotteryContent.substr(2,1):'',ge=item.lotteryContent!==null?item.lotteryContent.substr(3,1):'',winning_number_twoshow=[],winning_number_threeshow=[]
                            let qiangesum='',baishisum='',winning_number=''

                            if(item.lotteryContent!==null){
                            baishisum=(parseInt(bai)+parseInt(shi))%10+''
                            qiangesum=(parseInt(qian)+parseInt(ge))%10+''
                            winning_number=qian+bai+shi+ge
                            let first
                            let second
                            let three
                            let temp
                            let temp2
                            for(let i=0;i<winning_number.length;i++){
                                first=winning_number.substr(i,1)
                                temp=winning_number.replace(winning_number[i],'')
                                for(let j=0;j<temp.length;j++){
                                second=temp[j]
                                winning_number_twoshow.push(first+second)
                                temp2=temp.replace(temp[j],'')
                                for(let k=0;k<temp2.length;k++){
                                    three=temp2[k]
                                    winning_number_threeshow.push(first+second+three)
                                }
                                }
                            }
                            
                            }else{
                            baishisum=''
                            qiangesum=''
                            winning_number=''
                            }
                            
                            let itemData={
                            ...item,
                            qian,
                            bai,
                            shi,
                            ge,
                            qiangesum,
                            baishisum,
                            winning_number,
                            winning_number_twoshow,
                            winning_number_threeshow
                            }
                            return itemData
                        })
                        this.postLists=arr
                        console.log('本地的数据',this.postList)
                        console.log('添加中奖的数据',this.postLists)
                    }
        },
        //等接口
        gotohomeself(n){
            // console.log('去他的首页',n)
            // this.$router.push({
            //                     name:'ranking/myinfo',
            //                     query:{
            //                         userinfo:JSON.stringify(n)
            //                     }
            // })
        },
        gotoPost(n){
            // console.log('去到该帖子的评论页',n)
            // this.$router.push({
            //                     name:'ranking/mypost',
            //                     query:{
            //                         postinfo:JSON.stringify(n),
            //                         userinfo:JSON.stringify(this.userinfo)
            //                     }
            // })
        }
    },
    mounted(){
        console.log(this.schemename)
        // console.log(JSON.parse(this.$route.query.userinfo))
        this.getmasterTenPostInfo()
        
    }
}
</script>

<style lang="less" scoped>
*{
    padding: 0;
    margin:0;
}
p{
  
    font-size: 0.3rem;
    margin:0.1rem;

}
.red{
    color:red;
}
.black{
    color:black;
}
.head{
    width:100%;
    p{
        margin:0;
        padding:0;
        font-size: 0.3rem;
        // padding: 0.3rem 0;
        text-align:center;
    }
    .front{
        margin-left: 0.1rem;
        display: inline-block;
    }
    .after{
        margin-left: 6.6rem;
        display: inline-block;
    }
}
.body{
  
    .item{
        height: 2.5rem;
        width: 100%;
        margin: 0.1rem 0;
        padding: 0.1rem 0;
        background-color: white;

        .issue{
            margin: 0.7rem 0;
            float:left;
            width: 20%;
            display: inline-block;
        }
        .info{
            margin: 0.3rem 0;
            height: 80%;
            float:left;
            border-radius: 10px;
            width: 60%;
            display: inline-block;
            background-color: rgba(128, 128, 128, 0.173);
            p{
                text-align: left;;
            }
        }
        .icon{
            text-align: center;
            margin: 0.5rem 0;
            float:left;
            width: 20%;
            display: inline-block;
            div{
                display: inline-block;

            }
            .van-icon{
                padding:0.3rem 0;
                display: inline-block;
            }
            p{
                margin:0.3rem 0.5rem;
                display: inline-block;
            }
        }
    }
}
.homeself{
    position: fixed;
    width: 3rem;
    height: 1rem;
    text-align: center;
    top: 0rem;
    right: 0rem;
    z-index: 100;
    p{
        font-size: 0.4rem;
        padding: 0.3rem 0;
    }
}
</style>